import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

export const flattenData = (data) => {
  return data.reduce((acc, photosByTown) => {
    return photosByTown.images.reduce((acc, image) => {
      return [...acc, image];
    }, acc);
  }, []);
};

const NavigatorKeywordsTree = ({ filteredData, searchText, setCurrentPhoto, currentPhoto }) => {
  const data = useMemo(() => flattenData(filteredData), [filteredData]);
  return (
    <div>
      {data.length === 0 ?(
        <div className="alert alert-warning alert-xs p-2">
          Aucun résultat ne correspond à cette recherche
        </div>
      ):(
        <>
          {searchText.length === 0 ? (
            <div className="alert alert-secondary alert-xs p-2">
              Veuillez saisir un mot-clé
            </div>
          ) : (
            <>
              {(
                data.map((image) => (
                  <div key={image.file}
                       className="cursor-pointer navigator-item"
                       onClick={() => setCurrentPhoto(image)}>
                    - {image.town}
                    <span className={`badge ${currentPhoto && currentPhoto.file === image.file ? 'bg-success' : 'bg-primary'} ms-1`}>{image.index}</span>
                  </div>
                )))}
            </>
          )}
        </>
      )}

    </div>
  );
};

NavigatorKeywordsTree.defaultProps = {
  currentPhoto: null,
}

NavigatorKeywordsTree.propTypes = {
  searchText: PropTypes.array.isRequired,
  setCurrentPhoto: PropTypes.func.isRequired,
  currentPhoto: PropTypes.object,
  filteredData: PropTypes.array.isRequired
};

export default NavigatorKeywordsTree;
