import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import NavigatorKeywordsTree from './NavigatorKeywordsTree';

const handleClickOnTown = (setCurrentTown, setCurrentPhoto, town) => {
  setCurrentTown((prevState) => (prevState && prevState.town === town.town ? null : town));
  setCurrentPhoto(town.images[0])
}

const NavigatorAlphabetTree = ({
                                 currentPhoto,
                                 setCurrentPhoto,
                                 currentTown,
                                 setCurrentTown,
                                 filteredData
                               }) => {
  useEffect(() => {
    if (currentTown === null) {
      return;
    }
    //setTownOpen({[currentTown.town]: true});
    if (itemsRef.current[currentTown.town]) {
      window.setTimeout(() => {
        itemsRef.current[currentTown.town].scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 100);
    }
  }, [currentTown]);

  useEffect(() => {
    if (currentPhoto === null) {
      return;
    }
    //setTownOpen({[currentPhoto.town]: true});
    if (itemsRef.current[currentPhoto.town]) {
      window.setTimeout(() => {
        itemsRef.current[currentPhoto.town].scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 100);
    }
  }, [currentPhoto]);

  const itemsRef = useRef({});

  return (
    <div>
      <div>
        {filteredData.map((town, index) => (
          <div key={town.town}
               className="navigator-item"
               ref={el => itemsRef.current[town.town] = el}>
            <span className="cursor-pointer"
                  onClick={() => handleClickOnTown(setCurrentTown, setCurrentPhoto, town)}>
              {currentTown && currentTown.town === town.town ? (
                <FontAwesomeIcon className="navigator-icon"
                                 size="xs"
                                 icon={solid('chevron-down')} />
              ) : (
                <FontAwesomeIcon className="navigator-icon"
                                 size="xs"
                                 icon={solid('chevron-right')} />
              )}
              <span>{town.town}</span>
            </span>
            {currentTown && currentTown.town === town.town && (
              <div className="navigator-sub-items">
                {town.images.map((image) => (
                  <div key={image.file}
                       className="cursor-pointer"
                       onClick={() => setCurrentPhoto(image)}>
                    {image.town}
                    <span className={`badge ${currentPhoto && currentPhoto.file === image.file ? 'bg-success' : 'bg-primary'} ms-1`}>{image.index}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};


NavigatorKeywordsTree.defaultProps = {
  currentPhoto: null,
}


NavigatorAlphabetTree.propTypes = {
  setCurrentPhoto: PropTypes.func.isRequired,
  currentPhoto: PropTypes.object,
  setCurrentTown: PropTypes.func.isRequired,
  currentTown: PropTypes.object.isRequired,
};

export default NavigatorAlphabetTree;
