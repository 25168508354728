import React from 'react';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';


const Overlay = ({setShowOverlay, showOverlay}) => {

  return (
    <>
      {showOverlay && (
        <div id="overlay" className={`${isMobile ? 'mobile' : ''}`} onClick={() => setShowOverlay(false)}>
          <div className="overlay-content d-flex  justify-content-center h-100">
            <div className={`${isMobile ? 'w-75' : 'w-50'}`}>
              <img alt="logo" className="logo" src="./logo.png"/>
              <p>
                Bienvenue sur la Carte Photo Interactive du studio Kid A.
              </p>
              <p>
                Cette première version réunit 725 photographies géolocalisées,
                commandées par Grand Paris Seine & Oise au photographe Anthony VALON
                (Carte Blanche 2018-2019)
              </p>
              <p>
                Plusieurs possibilités de navigation possibles :<br/>
                - sur la carte : 1 icône = 1 photo géolocalisée<br/>
                - recherche par nom de ville<br/>
                - recherche par un ou plusieurs mots-clés<br/>
                - mode affichage grandes photos
              </p>
              <p>
                Photographie argentique, scans de négatifs ou de tirages.<br/>
                Matériel : Mamiya 645 - Fuji GA645Zi - Hasselblad XPAN
              </p>
              <p>
                infos :
                contact@studiokida.fr
                +33 6 07 18 49 21
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Overlay.propTypes = {
  showOverlay: PropTypes.bool.isRequired,
  setShowOverlay: PropTypes.func.isRequired,
};



export default Overlay;
