import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { GeoJSON } from 'react-leaflet';

const Wkt = require('../../node_modules/wicket/wicket-leaflet.js');



export const readGeometryAsJson = (wktGeometry) => {
  const wkt = new Wkt.Wkt();
  wkt.read(wktGeometry);
  return wkt.toJson();
};

const getStyle = (over, highlight) => {
  return ({
    weight: (over||highlight) ? 5 : 1,
    opacity: 1,
    color: "#3498db",
    fillOpacity: (over||highlight) ? 0.3 : 0.1,
  });
}

const TownBoundaries = ({highlight, data, onClick }) => {
  const geoJson = useMemo(() => readGeometryAsJson(data.wkt), [data]);
  const [over, setOver] = useState(false);
  return (
    <GeoJSON
      style={()=> getStyle(over, highlight)}
      data={geoJson}
      eventHandlers={{
        mouseover: (e) => {
          setOver(true);
        },
        mouseout: (e) => {
          setOver(false);
        }
      }}
    />
  );
};

TownBoundaries.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TownBoundaries;
