import React, { useMemo } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import PropTypes from 'prop-types';
import { isBrowser, isMobile } from 'react-device-detect';
import { MODE_KEYWORDS } from './Navigator';

const ImageViewer = ({
                       setCurrentPhoto,
                       carouselPhotos,
                       navigatorMode,
                       setImageViewerOpen,
                       currentPhoto,
                       searchText
                     }) => {
  const selectedItem = useMemo(() => carouselPhotos.findIndex((photo) => photo.file === currentPhoto.file),
    [carouselPhotos, currentPhoto]);
  const date = currentPhoto.date ? new Date(currentPhoto.date) : null;
  if(selectedItem === -1)return <>erreur</>;
  return (
    <div className={`image-viewer ${isMobile ? 'mobile' : ''}`}>
      <div className="d-flex">
        <span className="title">
          {carouselPhotos[selectedItem].town} ({carouselPhotos[selectedItem].index}){(date && date.getFullYear() > 1900) ? ` - ${date.getMonth() + 1}/${date.getFullYear()}` : ''}
        </span>

        <FontAwesomeIcon size="xl"
                         onClick={() => setImageViewerOpen(false)}
                         className="cursor-pointer ms-auto navigator-icon"
                         icon={solid('times')} />
      </div>
        <div className="mb-2">{navigatorMode === MODE_KEYWORDS && searchText.map((text) => (
          <span className="badge bg-success me-1">{text.label}</span>
        ))}</div>
      <Carousel showThumbs={isBrowser}
                onChange={(index) => setCurrentPhoto(carouselPhotos[index])}
                showIndicators={false}
                infiniteLoop
                swipeable
                selectedItem={selectedItem}>
        {carouselPhotos.map((image) => (
          <img key={image.file} alt={image.town} src={`images/1500/${image.town}/${image.file}`} />
        ))}
      </Carousel>
    </div>
  )
    ;
};


ImageViewer.propTypes = {
  setCurrentPhoto: PropTypes.func.isRequired,
  setImageViewerOpen: PropTypes.func.isRequired,
  currentPhoto: PropTypes.object.isRequired,
  searchText: PropTypes.array.isRequired,
  navigatorMode: PropTypes.string.isRequired
};

export default ImageViewer;
