import React, { useMemo, useCallback, useRef, useEffect, useState } from 'react';
import { Popup, Marker as LeafletMarker } from 'react-leaflet';
import L from 'leaflet';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import NavigatorKeywordsTree from './NavigatorKeywordsTree';

const orangeIcon = (index) => L.AwesomeMarkers.icon({
  markerColor: 'orange',
  extraClasses: `current-marker marker-with-number marker-number-${index}`,
});

const blueIcon = (index) => L.AwesomeMarkers.icon({
  markerColor: 'blue',
  extraClasses: `marker-with-number marker-number-${index}`
});

const Marker = ({ image, currentPhoto, setCurrentPhoto, imageViewerOpen, setImageViewerOpen }) => {
  const isCurrent = currentPhoto !== null && currentPhoto.file === image.file;
  const icon = useMemo(() => (isCurrent ? orangeIcon(image.index) : blueIcon(image.index)), [isCurrent]);
  const markerRef = useRef(null);

  useEffect(() => {
    const marker = markerRef.current
    if (marker) {
      if (imageViewerOpen) {
        marker.closePopup();
      } else if (isCurrent) {
        window.setTimeout(() => {
          marker.openPopup()
        }, 500);
      } else {
        marker.closePopup();
      }
    }
  }, [isCurrent, markerRef, imageViewerOpen]);

  const setCurrentPhotoCallback = useCallback((e) => {
    setCurrentPhoto(image);
  }, [setCurrentPhoto, image]);


  return (
    <>
      <LeafletMarker
        zIndex={isCurrent ? 1000 : 1}
        position={[image.latitude, image.longitude]}
        icon={icon}
        eventHandlers={{
          click: setCurrentPhotoCallback,
        }}
        ref={markerRef}
        style={{ data: image.file }}
      >
        <Popup maxWidth="100" minHeight="auto" maxHeight="auto">
          <div className="image-popup">
            <img alt={image.file}
                 onClick={() => setImageViewerOpen(true)}
                 className="w-100"
                 title={image.file}
                 src={`images/thumbnail/${image.town}/${image.file}`} />
            <div onClick={() => setImageViewerOpen(true)}
                 className="thumbnail-fullscreen">
              <FontAwesomeIcon beat
                               size="xl"
                               className="cursor-pointer"
                               icon={solid('expand')}>
              </FontAwesomeIcon>
            </div>
          </div>
        </Popup>
      </LeafletMarker>
    </>
  );
};


NavigatorKeywordsTree.defaultProps = {
  currentPhoto: null,
}


Marker.propTypes = {
  image: PropTypes.object.isRequired,
  imageViewerOpen: PropTypes.bool.isRequired,
  setImageViewerOpen: PropTypes.func.isRequired,
  setCurrentPhoto: PropTypes.func.isRequired,
  currentPhoto: PropTypes.object,
};

export default Marker;
