import React  from 'react';
import MarkerClusterGroup from 'react-leaflet-cluster';
import TownBoundaries from './TownBoundaries';
import Marker from './Marker';
import PropTypes from 'prop-types';
import NavigatorKeywordsTree from './NavigatorKeywordsTree';

const Town = ({ town, currentTown, setCurrentTown, setCurrentPhoto, currentPhoto,imageViewerOpen, setImageViewerOpen }) => {
  return (
    <MarkerClusterGroup
      spiderifyOnMaxZoom
    >
      {town.wkt !== null && (
        <TownBoundaries
          highlight={currentTown && currentTown.town === town.town}
          data={town}
        />
      )}
      {town.images.map((image) => (
        <Marker
          key={image.file}
          image={image}
          setCurrentPhoto={setCurrentPhoto}
          setCurrentTown={setCurrentTown}
          currentPhoto={currentPhoto}
          imageViewerOpen={imageViewerOpen}
          setImageViewerOpen={setImageViewerOpen}
        />
      ))}
    </MarkerClusterGroup>
  );
};


NavigatorKeywordsTree.defaultProps = {
  currentPhoto: null,
  currentTown: null,

}


Town.propTypes = {
  town: PropTypes.object.isRequired,
  currentTown: PropTypes.object,
  imageViewerOpen: PropTypes.bool.isRequired,
  setImageViewerOpen: PropTypes.func.isRequired,
  setCurrentPhoto: PropTypes.func.isRequired,
  currentPhoto: PropTypes.object,
  setCurrentTown: PropTypes.func.isRequired,
};

export default Town;
