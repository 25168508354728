import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MapContainer } from 'react-leaflet/MapContainer'
import { TileLayer } from 'react-leaflet/TileLayer'
import photos from '../data/photos.json'
import "leaflet/dist/leaflet.css";
import L from 'leaflet';
import 'leaflet.awesome-markers';
import 'leaflet-active-area';
import 'leaflet.awesome-markers/dist/leaflet.awesome-markers.css';
import Town from './Town';
import { LayersControl, ZoomControl } from 'react-leaflet';
import { isMobile } from 'react-device-detect';
import NavigatorKeywordsTree from './NavigatorKeywordsTree';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';

import('@changey/react-leaflet-markercluster/dist/styles.min.css');

delete L.Icon.Default.prototype._getIconUrl;


const { BaseLayer } = LayersControl;


L.Icon.Default.mergeOptions({
  // eslint-disable-next-line global-require
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  // eslint-disable-next-line global-require
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  // eslint-disable-next-line global-require
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


export const getBounds = (data) => {
  const markers = data.reduce((acc, photosByTown) => {
    return photosByTown.images.reduce((acc, photo) => {
      const newMarker = new L.Marker([photo.latitude, photo.longitude]);
      return [...acc, newMarker];
    }, acc);
  }, []);
  const group = new L.featureGroup(markers);
  return group.getBounds();
};

/*const panToOffset = (map, latlng, offset, zoom) => {
    const x = map.latLngToContainerPoint(latlng).x - offset[0]
    const y = map.latLngToContainerPoint(latlng).y - offset[1]
    const point = map.containerPointToLatLng([x, y])
    map.setView(point, zoom);
    map.zoomIn()
}*/

const panToMarker = (map, image) => {
  let zoom = 15;
  let zoomChanged =  map.current._zoom !== 15

  if (map.current._zoom > 15) {
    zoom = map.current._zoom;
  }
  map.current.setView([image.latitude, image.longitude], zoom);
  return zoomChanged;
}

const viewPortClass = isMobile ? 'viewportMobile' : 'viewport';

const initializeMap = (map) => {
  if (map) {
    map.setActiveArea(viewPortClass, true);
    map.fitBounds(getBounds(photos));
    map.setZoom(9);
    map.on('zoomend', function () {
      //
    })
  }
}

const Map = ({
               currentPhoto,
               setCurrentPhoto,
               currentTown,
               setCurrentTown,
               imageViewerOpen,
               setImageViewerOpen,
               data
             }) => {
  const map = useRef(null);
  useEffect(() => {
    if (map.current && currentPhoto) {
      panToMarker(map, currentPhoto, imageViewerOpen);
      map.current.eachLayer(function (layer) {     //iterate over map rather than clusters

        if (layer.getChildCount) {         // if layer is markerCluster
          if (layer._childCount > 0) {
            const marker = layer.getAllChildMarkers().find((marker) => {
              return marker.options.style.data === currentPhoto.file;
            });
            if (marker) {
              window.setTimeout(() => {
                layer.spiderfy();
              }, 300)
            }
          }
        }
      })
    }
  }, [currentPhoto, imageViewerOpen]);

  useEffect(() => {
    if (map && map.current) {
      if (imageViewerOpen) {
        map.current.setActiveArea(viewPortClass + ' imageViewerOpen', true);
      } else {
        map.current.setActiveArea(viewPortClass, true);
      }
    }
  }, [imageViewerOpen])

  useEffect(() => {
    if (map.current && Object.keys(data).length > 0) {
      const bounds = getBounds(data);
      if (Object.keys(bounds).length > 0) {
        map.current.fitBounds(getBounds(data));
      }
    }
  }, [map, data]);

  return (
    <MapContainer bounds={getBounds(photos)}
                  style={{ width: '100%', height: '100vh' }}
                  center={null}
                  zoom={12}
                  scrollWheelZoom
                  ref={map}
                  zoomControl={false}
                  whenReady={({ target: map }) => {
                    setTimeout(() => {
                      initializeMap(map)
                    }, 1);
                  }}
    >
      <ZoomControl position="bottomright" />

      <LayersControl position={isMobile ? "bottomright":"topright"}>
        <BaseLayer checked name="Carte OpenStreetMap">
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </BaseLayer>
        <BaseLayer name="Carte Satellite">
          <ReactLeafletGoogleLayer apiKey="AIzaSyBcUly2Rzfeb9pv2Z7xO5vM-9Qm1zjfCOE"
                                   type={'satellite'} />
        </BaseLayer>
      </LayersControl>


      {data.map((town) => (
        <Town
          key={town.town}
          imageViewerOpen={imageViewerOpen}
          setImageViewerOpen={setImageViewerOpen}
          setCurrentTown={setCurrentTown}
          setCurrentPhoto={setCurrentPhoto}
          currentPhoto={currentPhoto}
          currentTown={currentTown}
          town={town}
        />
      ))
      }

    </MapContainer>
  );
};

NavigatorKeywordsTree.defaultProps = {
  currentPhoto: null,
}


Map.propTypes = {
  data: PropTypes.array.isRequired,
  imageViewerOpen: PropTypes.bool.isRequired,
  setImageViewerOpen: PropTypes.func.isRequired,
  setCurrentPhoto: PropTypes.func.isRequired,
  currentPhoto: PropTypes.object,
  setCurrentTown: PropTypes.func.isRequired,
  currentTown: PropTypes.object.isRequired,
};

export default Map;
