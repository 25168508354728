import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './App.scss';
import Map from './Components/Map';
import Navigator, { MODE_ALPHABET } from './Components/Navigator';
import { useEffect, useMemo, useState } from 'react';
import ImageViewer from './Components/ImageViewer';
import photos from './data/photos.json';
import { isMobile } from 'react-device-detect';
import { flattenData } from './Components/NavigatorKeywordsTree';
import Overlay from './Components/Overlay';

export const filterByTown = (data, searchText) => {
  if (searchText.length === 0) return data;
  const towns = searchText.map((value) => value.value);
  return data.filter((imagesByTown) => {
    return towns.includes(imagesByTown.town);
  });
};

export const filterByKeywords = (data, searchText) => {
  if (searchText.length === 0) return data;
  const keywords = searchText.map((value) => value.value);
  let filteredData = [];
  data.forEach((imagesByTown) => {
    const { images, ...other } = imagesByTown;
    let filteredImages = [];
    images.forEach((image) => {
      if (keywords.every((keyword) => {
        return image.keywords.includes(keyword);
      })) {
        filteredImages = [...filteredImages, image];
      }
    })
    filteredData = [...filteredData, { ...other, images: filteredImages }]
  });
  return filteredData;
};

const getCarouselPhotos = (navigatorMode, currentPhoto, filteredData, searchText) => {
  if (navigatorMode === MODE_ALPHABET) {
    if (currentPhoto) {
      return photos.find((item) => item.town === currentPhoto.town).images;
    }
    return [];
  } else {
    if (searchText.length > 0) {
      return flattenData(filteredData);
    }
    if (currentPhoto) {
      return [currentPhoto];
    }
    return [];
  }
}

const getOverlayInitialState = () => {
  const shown = localStorage.getItem('overlay_shown');
  return !(shown === 'yes');
}

function App() {
  const [currentPhoto, setCurrentPhoto] = useState(null);
  const [currentTown, setCurrentTown] = useState(null);
  const [imageViewerOpen, setImageViewerOpen] = useState(false);
  const [searchText, setSearchText] = useState([]);

  const [navigatorMode, setNavigatorMode] = useState(MODE_ALPHABET);
  const filteredData = useMemo(() => navigatorMode === MODE_ALPHABET ? filterByTown(photos,
    searchText) : filterByKeywords(photos, searchText), [navigatorMode, searchText]);

  const carouselPhotos = getCarouselPhotos(navigatorMode, currentPhoto, filteredData, searchText);


  const [showOverlay, setShowOverlay] = useState(getOverlayInitialState());
  useEffect(() => {
    localStorage.setItem('overlay_shown', 'yes');
  }, [showOverlay])


  useEffect(() => {
    setSearchText([]);
  }, [navigatorMode]);

  useEffect(() => {
    setCurrentPhoto(null);
    setImageViewerOpen(false);
  }, [searchText]);

  useEffect(() => {
    if (currentPhoto) {
      const photosByTown = filteredData.find((item) => {
        return item.town === currentPhoto.town
      });
      setCurrentTown(photosByTown);
    }
  }, [currentPhoto, filteredData]);


  /*useEffect(() => {
    if (currentTown) {
      setCurrentPhoto(currentTown.images[0])
    }
  }, [currentTown, filteredData]);*/

  return (
    <div className="App">
      <Overlay showOverlay={showOverlay} setShowOverlay={setShowOverlay} />
      {!(isMobile && imageViewerOpen) && (
        <Navigator
          showOverlay={showOverlay}
          setShowOverlay={setShowOverlay}
          navigatorMode={navigatorMode}
          searchText={searchText}
          setSearchText={setSearchText}
          currentTown={currentTown}
          setCurrentTown={setCurrentTown}
          currentPhoto={currentPhoto}
          setCurrentPhoto={setCurrentPhoto}
          setNavigatorMode={setNavigatorMode}
          filteredData={filteredData}
        />
      )}
      <Map
        searchText={searchText}
        data={filteredData}
        currentTown={currentTown}
        setCurrentTown={setCurrentTown}
        currentPhoto={currentPhoto}
        setCurrentPhoto={setCurrentPhoto}
        setImageViewerOpen={setImageViewerOpen}
        imageViewerOpen={imageViewerOpen}
      />
      {currentPhoto && carouselPhotos.length > 0 && imageViewerOpen && (
        <ImageViewer
          navigatorMode={navigatorMode}
          carouselPhotos={carouselPhotos}
          searchText={searchText}
          setCurrentPhoto={setCurrentPhoto}
          currentPhoto={currentPhoto}
          setImageViewerOpen={setImageViewerOpen} />
      )
      }
    </div>
  );
}

export default App;
