import React from 'react';
import { ButtonGroup, Button, Card } from 'react-bootstrap';
import NavigatorAlphabetTree from './NavigatorAlphabetTree';
import NavigatorKeywordsTree from './NavigatorKeywordsTree';
import PropTypes from 'prop-types';
import Select from "react-select";
import photos from '../data/photos.json';
import { isMobile } from 'react-device-detect';

export const MODE_ALPHABET = 'alphabet';
export const MODE_KEYWORDS = 'keywords';


const getOptions = (navigatorMode) => {
  if (navigatorMode === MODE_ALPHABET) {
    return photos.reduce((acc, photosByTown) => {
      return [...acc, photosByTown.town];
    }, []).map((town) => ({ label: town, value: town }))
  } else {
    return photos.reduce((acc, photosByTown) => {
      return photosByTown.images.reduce((acc, image) => {
        return [...new Set([...acc, ...image.keywords])];
      }, acc)
    }, []).sort(Intl.Collator().compare).map((keyword) => ({ label: keyword, value: keyword }))
  }

}

const Navigator = ({
                     setCurrentPhoto,
                     setSearchText,
                     searchText,
                     currentPhoto,
                     setCurrentTown,
                     currentTown,
                     setNavigatorMode,
                     navigatorMode,
                     filteredData,
                     setShowOverlay
                   }) => {
  const options = getOptions(navigatorMode);
  return (
    <div className={`navigator ${isMobile ? 'mobile' : ''}`}>
      <Card className="link-card">
        <Card.Body>
          <div className="d-flex align-items-center">
            <a
              rel="noreferrer"
              target="_blank"
              href="https://studiokida.fr"
              title="studiokida.fr"><img alt="logo" className="link-card-logo" src="logo.png"/></a>
            <div>
              <div>Carte Blanche Grand Paris Seine & Oise</div>
              <div>par Anthony Valon</div>
            </div>
          </div>

        </Card.Body>
        <Card.Footer>
          <div className="link-card-buttons d-flex mt-1 ml-1 justify-content-end">
            <a className="text-white mr-1" onClick={() => setShowOverlay(true)}>
              → infos
            </a>
            <a target="_blank" rel="noreferrer" className="text-white ml-2" href="https://www.bazabuque.com/gpseo">
              → albums
            </a>
          </div>
        </Card.Footer>
      </Card>
      <Card className="navigator-card">
        <Card.Body>
          <div style={{height:'100%'}} className="d-flex flex-column">
            <div className="navigator-search-bar">
              <Select
                isClearable
                value={searchText}
                onChange={(value) => setSearchText(value)}
                isMulti
                options={options}
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder="Votre recherche"
              />
              <ButtonGroup size="sm" className="w-100 mt-2 mb-2" aria-label="Basic example">
                <Button variant="secondary"
                        onClick={() => setNavigatorMode(MODE_ALPHABET)}
                        active={navigatorMode === MODE_ALPHABET}>Par ville</Button>
                <Button variant="secondary"
                        onClick={() => setNavigatorMode(MODE_KEYWORDS)}
                        active={navigatorMode === MODE_KEYWORDS}>Par mots-clés</Button>
              </ButtonGroup>
            </div>
            <div className="navigator-search-tree">
              {navigatorMode === MODE_ALPHABET && (
                <NavigatorAlphabetTree
                  filteredData={filteredData}
                  currentTown={currentTown}
                  setCurrentTown={setCurrentTown}
                  setCurrentPhoto={setCurrentPhoto}
                  currentPhoto={currentPhoto}
                />
              )}
              {navigatorMode === MODE_KEYWORDS && (
                <NavigatorKeywordsTree
                  filteredData={filteredData}
                  currentTown={currentTown}
                  setCurrentTown={setCurrentTown}
                  setCurrentPhoto={setCurrentPhoto}
                  currentPhoto={currentPhoto}
                  searchText={searchText}
                />
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
    ;
};

NavigatorKeywordsTree.defaultProps = {
  currentPhoto: null,
}


Navigator.propTypes = {
  setCurrentPhoto: PropTypes.func.isRequired,
  currentPhoto: PropTypes.object,
  setCurrentTown: PropTypes.func.isRequired,
  currentTown: PropTypes.object.isRequired,
  setNavigatorMode: PropTypes.func.isRequired,
  navigatorMode: PropTypes.string.isRequired,
  setSearchText: PropTypes.func.isRequired,
  searchText: PropTypes.array.isRequired,
  filteredData: PropTypes.array.isRequired,
  setShowOverlay: PropTypes.func.isRequired,
};

export default Navigator;
